.word-info-popover {
  position: fixed !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-family: initial;
  transform: initial !important;
  margin: 0;
  top: 0 !important;
  left: 0 !important;
  max-width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
}

.word-info-popover-inner {
  width: auto;
  height: auto;
  max-width: 80vw;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  @include subtle-drop-shadow
}

.carousel-arrow-left {
  left: initial;
  right: 10px;
  top: 19px;
  transform: translateY(-50%);
  font-weight: bold;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-arrow-right {
  right: -20px;
  top: 19px;
  transform: translateY(-50%);
  font-weight: bold;
}