.full-height {
  min-height: calc(100vh - 165px);
}

.margin-below-logo {
  margin-top: 90px;
}

.full-parent-height {
  min-height: 100%;
}