@import "mixins";

.btn-huge {
  padding: 1rem;
  width: 30rem;
  max-width: 100%;
  font-size: 1.8rem;
  line-height: 1.8rem;
}

@media (min-width: 1024px) {
  .btn-huge-responsive {
    padding: 1rem;
    width: 30rem;
    max-width: 100%;
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
}

@media (max-width: 1023px) {
  .btn-huge-responsive {
    padding: 1rem;
    width: 12rem;
    max-width: 100%;
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
}


.btn {
  @include subtle-box-shadow;
  @include anton-font;
}

.btn-link {
  box-shadow: none;
}

.clickable {
  cursor: pointer;
}