.fade-in {
  opacity: 0;
  animation: .8s ease-in fade-in;
  animation-fill-mode: forwards;

  @media (max-width: 500px) {
    animation: .5s ease-in fade-in;
    animation-fill-mode: forwards;
  }
}

@keyframes fade-in {
  100% { opacity: 1; }
}
