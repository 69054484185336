.image-sixteen-by-nine {
  width: 100%;
  height: 0;
  padding-bottom: 32%;
  position: relative;
  
  img {
    width: 100%;
    height:100%;
    object-fit: cover;
    position: absolute;
  }
}