@import "mixins";

.subtle-shadow {
  @include subtle-box-shadow
}

.subtle-shadow-drop {
  @include subtle-drop-shadow
}

.svg-drop-shadow {
  filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.8))
}

.subtle-shadow-text {
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);;
}

.border-radius-1-5 {
  border-radius: 1.5rem;
}

.border-radius-1 {
  border-radius: 1rem;
}

.border-inset {
  border: inset black 2px;
  border-radius: 5px;
}

.border-radius-0 {
  border-radius: 0;
}

.right-divider {
  border-right: thin solid #fff9e0;
}