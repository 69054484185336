@import "mixins";

/*font sizes*/
.fs-15rem {
  font-size: 15rem;
}

.fs-2-1rem {
  font-size: 2.1rem;
}

.fs-3rem {
  font-size: 3rem;
}

.fs-2rem {
  font-size: 2rem;
}

.fs-2-5rem {
  font-size: 2.5rem;
}

.fs-1-5rem {
  font-size: 1.5rem;
}

.fs-1rem {
  font-size: 1rem;
}

.fs-1-2rem {
  font-size: 1.2rem;
}


@media (min-width: 1024px) {
  .text-large-lg {
    font-size: 1.3rem;
  }
}

.lh-3rem {
  line-height: 3rem;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.kai-ti {
  font-family: "KaiTi", "楷体", STKaiti, "华文楷体", serif;
}

.fs-2rem-sm {
  @media (max-width: 500px) {
    font-size: 2rem;
  }
}

.fs-2-5rem-sm {
  @media (max-width: 500px) {
    font-size: 2.5rem;
  }
}

// Anton

@font-face {
  font-family: 'Anton';
  src: url('https://media.mistermandarin.com/media/fonts/anton-regular-webfont.woff2') format('woff2');
  font-display: swap;
}

.anton {
  @include anton-font
}

.underline {
  text-decoration: underline;
}

h1, h2, h3, h4, h5 {
  @include anton-font
}

label {
  @include anton-font
}

// Fontello

@font-face {
  font-family: 'fontello';
  src: url('https://media.mistermandarin.com/media/fonts/fontello.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?44999039#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-play:before { content: '\e800'; } /* '' */
.icon-volume-up:before { content: '\e801'; } /* '' */
.icon-level-up:before { content: '\e802'; } /* '' */
.icon-brush:before { content: '\f1fc'; } /* '' */
.icon-facebook-official:before { content: '\f230'; } /* '' */

