$primary: #1B4299;
$danger: #EE162F;
$success: #C30E23;
$info: #1B4299;
$warning: #EE6916;
$correct: #02b31d;
$muted: #6c757d;
$parchment: #fff9e0;

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$custom-colors: (
        "parchment": $parchment,
        "correct": $correct,
        "muted": $muted,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

@import "../../node_modules/bootstrap/scss/bootstrap";