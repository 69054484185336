.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.spinner-overlay-background {
  background-color: #FFFA;
}

.spinner-overlay > .spinner-border {
  height: 7em;
  width: 7em;
  max-height: 100%;
  aspect-ratio: 1 / 1;
  border-width: 0.45em;
  animation: spinner-border 1s linear infinite;
  background-color: #fcfcfc88
}