@mixin subtle-box-shadow {
  box-shadow:  0px 2px 2px rgba(0,0,0,0.2);
}

@mixin subtle-drop-shadow {
  drop-shadow:  0px 2px 2px rgba(0,0,0,0.2);
}

@mixin anton-font {
  font-family: Anton, Impact, sans-serif;
}