@import "bootstrap_overrides";
@import "common/text";
@import "common/loaders";
@import "common/layout";
@import "common/buttons";
@import "common/images";
@import "common/borders";
@import "common/animations";
@import "common/elementDecoration";
@import "componentStyles/articlePage";
@import "componentStyles/home";
@import "componentStyles/lessonEditor";
@import "componentStyles/navMenu";
@import "componentStyles/popover";


html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  font-family: sans-serif;
}

body {
  background-color: #FCFCFC;
}

#root {
  min-height: 100vh;
}

#main-content-wrapper {
  flex-grow: 1;
}

.home-page-background {
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
}

.card {
  background-color: #fffe;
}

.card-header {
  background-color: transparent;
  border-bottom: none;
}